import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Context } from "./Multilanguage/Wrapper";

import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import { FormattedMessage } from "react-intl";
import CardItem from "./CardItem";

export default function TabsAppartments() {
  const [activeTab, setActiveTab] = useState("penthouse");

  const data = [
    {
      label: <FormattedMessage id="penthouses" />,
      value: "penthouse",
    },
    {
      label: <FormattedMessage id="apartments" />,
      value: "apartment",
    },
    {
      label: <FormattedMessage id="townhouses" />,
      value: "townhouse",
    },
    {
      label: <FormattedMessage id="villas" />,
      value: "villa",
    },
  ];

  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);
  const { locale } = useContext(Context);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/sells`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setItems(data);
      } catch (error) {
        setError(error);
      } finally {
      }
    };

    fetchItems();
  }, []);

  return (
    <>
      <Tabs value={activeTab} className="my-12 px-6 xl:px-0">
        <TabsHeader
          className="rounded-none  bg-transparent p-0"
          indicatorProps={{
            className:
              "bg-transparent border-b-[1px] border-gold shadow-none rounded-none",
          }}
        >
          {data.map(({ label, value }) => (
            <Tab
              key={value}
              value={value}
              onClick={() => setActiveTab(value)}
              dir={locale == "ar" ? "rtl" : ""}
              className={`${
                activeTab === value
                  ? "text-gold py-4 px-0 text-sm sm:text-base"
                  : "text-white py-4 px-0 text-sm sm:text-base"
              } ${locale === "ar" ? "justify-start" : ""}`}
            >
              {label}
            </Tab>
          ))}
        </TabsHeader>
        <TabsBody className="mt-8">
          {data.map(({ value }) => (
            <TabPanel key={value} value={value} className="px-0">
              <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
                {items &&
                  items
                    .filter((itms) => itms.unit_type == value)
                    .slice(0, 3)
                    .map((item, index) => {
                      return (
                        <CardItem
                          key={index}
                          data={item}
                          pagePath="buy"
                          path="home"
                        />
                      );
                    })}
              </div>
            </TabPanel>
          ))}
        </TabsBody>
      </Tabs>
    </>
  );
}
