import React, { useEffect, useState } from "react";
import StandardContainer from "../Components/StandardContainer";
import CardItem from "../Components/CardItem";
import { FormattedMessage } from "react-intl";

export default function Wishlist() {
  const [wishlistItems, setWishlistItems] = useState([]);

  useEffect(() => {
    const fetchWishlistItems = () => {
      const wishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
      setWishlistItems(wishlist);
    };
    fetchWishlistItems();
    const handleWishlistChange = (event) => {
      const wishlist = event.detail.wishlist;
      setWishlistItems(wishlist);
    };

    window.addEventListener("wishlistChange", handleWishlistChange);

    return () => {
      window.removeEventListener("wishlistChange", handleWishlistChange);
    };
  }, []);

  const handleClearWishlist = () => {
    localStorage.removeItem("wishlist");
    setWishlistItems([]);
    window.dispatchEvent(
      new CustomEvent("wishlistChange", { detail: { wishlist: [] } })
    );
  };

  const content = (
    <div>
      {wishlistItems.length > 0 && (
        <button
          onClick={handleClearWishlist}
          className="mt-32 mb-12  text-gold text-2xl font-link-title"
        >
          <FormattedMessage id="clear_all" />
        </button>
      )}
      {wishlistItems.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 mb-24">
          {wishlistItems.map((item, index) => (
            <CardItem data={item} key={index} pagePath="buy" path="home" />
          ))}
        </div>
      ) : (
        <p className="py-32 text-center text-3xl text-gold text-md font-link-title">
          <FormattedMessage id="no_wishlist" />
        </p>
      )}
    </div>
  );

  return <StandardContainer content={content} />;
}
