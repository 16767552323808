import { useEffect, useState } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  ZoomControl,
} from "react-leaflet";
import L from "leaflet";
import iconUrl from "leaflet/dist/images/marker-icon.png";
import iconRetinaUrl from "leaflet/dist/images/marker-icon-2x.png";
import shadowUrl from "leaflet/dist/images/marker-shadow.png";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: iconRetinaUrl,
  iconUrl: iconUrl,
  shadowUrl: shadowUrl,
});
export default function MapComponent({ address }) {
  const [location, setLocation] = useState(null);

  useEffect(() => {
    // Geocode the address to get the coordinates
    fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${address}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          setLocation([data[0].lat, data[0].lon]);
        }
      });
  }, [address]);

  if (!location) return null;

  return (
    <>
      <MapContainer
        center={location}
        zoom={40}
        style={{ height: "500px", width: "100%" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a aria-label="Map" href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={location}>
          <Popup>{address}</Popup>
        </Marker>
        <ZoomControl position="topright" />
      </MapContainer>
    </>
  );
}
