import { useState, useEffect, useContext } from "react";
import { Context } from "./Multilanguage/Wrapper";
import ModalForm from "./ModalForm";
import { FormattedMessage } from "react-intl";
import Title from "./Title";
import Description from "./Description";

export default function HomeBanner({ path }) {
  const [videos, setVideos] = useState([]);
  const [error, setError] = useState(null);
  const { locale } = useContext(Context);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/home-videos`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setVideos(data);
      } catch (error) {
        setError(error);
      } finally {
      }
    };
    fetchVideos();
  }, []);

  return (
    <>
      <div className="w-full h-screen relative">
        {videos &&
          videos.map((item, index) => {
            if (path == item.location) {
              return (
                <div key={index}>
                  <video
                    className="absolute top-0 left-0 w-full h-full object-cover"
                    muted
                    autoPlay
                    loop
                    playsInline
                  >
                    <source
                      src={`${process.env.REACT_APP_API_BASE_URL}/uploads/videos/${item.video_path}`}
                      type="video/mp4"
                    />
                    <track
                      kind="captions"
                      // src={`${process.env.REACT_APP_API_BASE_URL}/uploads/videos/${item.video_path}`}
                      srcLang="en"
                      label="English"
                      default
                    />
                  </video>
                  <div className="absolute  pt-6 sm:pt-0 z-20 left-0 bg-black/50 w-full h-full">
                    <div className="container h-full px-6 xl:px-0 mx-auto md:flex md:flex-col">
                      <div className="h-1/2 flex flex-col sm:justify-end gap-6">
                        <Title
                          head={true}
                          text={item[`title_${locale}`]}
                          styles="font-bold  text-white text-xl md:text-6xl lg:w-full"
                        />
                        <Description
                          dangerouslySetInnerHTML
                          dangerousContent={{
                            __html: item[`description_${locale}`],
                          }}
                          styles="text-white/70 text-md  sm:text-2xl font-normal"
                        />
                      </div>
                      <div className="sm:h-1/2 relative z-50  text-white text-2xl flex justify-center items-center">
                        <ModalForm
                          buttonTitle={<FormattedMessage id="explore_button" />}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          })}
      </div>
    </>
  );
}
