import React, { useContext } from "react";
import { Context } from "./Wrapper";
import ArFlag from "../../assets/images/lang/ar.png";
import EnFlag from "../../assets/images/lang/flag-3d-round-250.png";
import RuFlag from "../../assets/images/lang/ruFlag.png";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

function Lang() {
  const context = useContext(Context);

  return (
    <>
      <div className="select">
        <FormControl variant="outlined">
          <InputLabel id="language-select-label">Language</InputLabel>
          <Select
            labelId="language-select-label"
            id="language-select"
            value={context.locale}
            onChange={context.selectLanguage}
            label="Language"
            aria-label="Select language"
          >
            <MenuItem value="en">
              <img src={EnFlag} width={24} height={24} alt="English" />
            </MenuItem>
            <MenuItem value="ar">
              <img src={ArFlag} width={24} height={24} alt="Arabic" />
            </MenuItem>
            <MenuItem value="ru">
              <img src={RuFlag} width={24} height={24} alt="Russian" />
            </MenuItem>
          </Select>
        </FormControl>
      </div>
    </>
  );
}
export default Lang;
