import { useState, useEffect } from "react";
import { Context } from "./Multilanguage/Wrapper";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useContext } from "react";

import { FaRegBookmark, FaBookmark } from "react-icons/fa";

import BadLogo from "../assets/images/icons/BedIcon.png";
import BathLogo from "../assets/images/icons/Bathub.png";
import LocationLogo from "../assets/images/icons/location.png";
import Slider from "react-slick";
import SqrtLogo from "../assets/images/icons/Sqrt.png";
import AppartmentLogo from "../assets/images/icons/apartment.png";
import FurnishedLogo from "../assets/images/icons/Furnished.png";
import NoFurnishedLogo from "../assets/images/icons/NoFurnished.png";
import Title from "./Title";
import Description from "./Description";

export default function CardItem({ data, path, pagePath, whishListType }) {
  const { locale } = useContext(Context);
  const [isInWishlist, setIsInWishlist] = useState(false);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  useEffect(() => {
    const wishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
    setIsInWishlist(wishlist.some((item) => item.id === data?.id));
  }, [data?.id]);

  const toggleWishlist = () => {
    let wishlist = JSON.parse(localStorage.getItem("wishlist")) || [];

    if (wishlist.some((item) => item.id === data.id)) {
      wishlist = wishlist.filter((item) => item.id !== data.id);
    } else {
      wishlist.push(data);
    }

    localStorage.setItem("wishlist", JSON.stringify(wishlist));
    setIsInWishlist(!isInWishlist);

    const event = new CustomEvent("wishlistChange", {
      detail: { wishlist },
    });
    window.dispatchEvent(event);
  };

  return (
    <div className="rounded-lg border-[1px] border-gold overflow-hidden relative">
      <button
        onClick={toggleWishlist}
        className="text-white text-2xl absolute top-0 right-0 z-30 bg-black flex justify-center items-center w-20 h-12 gap-2"
      >
        {isInWishlist ? (
          <FaBookmark className="text-gold" title="Remove From WhishList" />
        ) : (
          <FaRegBookmark className="text-gold" title="Add To WhishList" />
        )}
      </button>
      <Link to={`/${pagePath}/${data?.slug}`}>
        <div>
          <div className="">
            <Slider {...settings} className="h-full">
              <div className="h-64">
                <img
                  src="https://www.apartments.com/blog/sites/default/files/styles/large/public/image/2023-06/the-huntley-atlanta-ga-luxury-apartment-view.jpg?itok=l26TswZZ"
                  alt={data?.title}
                  className="h-full w-full object-cover"
                />
              </div>
              <div className="h-64">
                <img
                  src="https://static.theceomagazine.net/wp-content/uploads/2020/09/04114637/luxury-apartments.jpg"
                  alt={data?.title}
                  className="h-full w-full object-cover"
                />
              </div>
              <div className="h-64">
                <img
                  src="https://i.pinimg.com/originals/24/e8/f0/24e8f08ba83e34213572acbdb1061bf0.jpg"
                  alt={data?.title}
                  className="h-full w-full object-cover"
                />
              </div>
            </Slider>
          </div>
          <div className="p-4 flex flex-col space-y-3">
            <div className="text-white h-12 font-normal">
              <Title head={false} text={data?.[`building_${locale}`]} />
            </div>
            <Description
              styles="flex w-full gap-4 text-white  font-normal"
              content={
                <>
                  <div>
                    <img src={LocationLogo} alt="" />
                  </div>
                  <div>{data?.[`location_${locale}`]}</div>
                </>
              }
            />
            <Description
              styles="flex gap-4"
              content={
                <>
                  {" "}
                  <div>
                    <img src={AppartmentLogo} alt="" />
                  </div>
                  <div className="text-white font-normal capitalize">
                    {data?.unit_type == "apartment" && (
                      <FormattedMessage id="apartments" />
                    )}
                    {data?.unit_type == "penthouse" && (
                      <FormattedMessage id="penthouses" />
                    )}
                    {data?.unit_type == "villa" && (
                      <FormattedMessage id="villas" />
                    )}
                    {data?.unit_type == "townhouse" && (
                      <FormattedMessage id="townhouses" />
                    )}
                    {data?.unit_type == "duplex" && (
                      <FormattedMessage id="duplex" />
                    )}
                    {data?.unit_type == "bulk_unit_rent" && (
                      <FormattedMessage id="bulk_unit_rent" />
                    )}
                    {data?.unit_type == "compound" && (
                      <FormattedMessage id="compound" />
                    )}
                  </div>
                </>
              }
            />
            <div
              className={`${
                locale == "ar" ? "justify-end" : "justify-start"
              } flex gap-8`}
            >
              <div className="flex items-center gap-4">
                <div>
                  <img src={BadLogo} alt="" />
                </div>
                <div className="text-white font-normal ">
                  {data?.bedrooms_number}
                </div>
              </div>
              <div className="flex items-center gap-4">
                <div>
                  <img src={BathLogo} alt="" />
                </div>
                <div className="text-white font-normal ">
                  {data?.bathrooms_number}
                </div>
              </div>
              <div className="flex items-center gap-4">
                <div>
                  <img src={SqrtLogo} alt="" />
                </div>
                <div className="text-white font-normal">{data?.size}</div>
              </div>
              <div className="flex items-center gap-4">
                {data?.furnished == 0 ? (
                  <div className="flex gap-4">
                    <div>
                      <img src={FurnishedLogo} alt="" />
                    </div>
                    <div className="text-white font-normal">
                      <FormattedMessage id="yes" />
                    </div>
                  </div>
                ) : (
                  <div className="flex gap-4">
                    <div>
                      <img src={NoFurnishedLogo} alt="" />
                    </div>
                    <div className="text-white font-normal">
                      <FormattedMessage id="no" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {path == "rents" && (
            <div className="text-white py-2 bg-gold text-center font-bold">
              <span className="pr-[1px]">$</span> {data?.price} /{" "}
              <FormattedMessage id="month" />
            </div>
          )}
          {path == "home" && (
            <div className="text-white py-2 bg-gold text-center font-bold">
              <span className="pr-[1px]">$</span> {data?.price}
            </div>
          )}
        </div>
      </Link>
    </div>
  );
}
