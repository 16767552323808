import { useState } from "react";
import {
  Button,
  Dialog,
  Card,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Checkbox,
  Radio,
} from "@material-tailwind/react";
import { useIntl, FormattedMessage } from "react-intl";

export default function ModalForm({ buttonTitle }) {
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [answers, setAnswers] = useState({
    propertyType: "",
    sizePreference: [],
    areaType: [],
    purpose: "",
    name: "",
    lastName: "",
    phoneNumber: "",
    email: "",
  });
  const intl = useIntl();

  const handleOpen = () => {
    setOpen((cur) => !cur);
    setStep(0);
    setSubmitted(false);
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === "sizePreference" || name === "areaType") {
      if (checked) {
        setAnswers((prev) => ({
          ...prev,
          [name]: [...prev[name], value],
        }));
      } else {
        setAnswers((prev) => ({
          ...prev,
          [name]: prev[name].filter((item) => item !== value),
        }));
      }
    } else {
      setAnswers({ ...answers, [name]: value });
    }
  };

  const handleNext = () => {
    if (step < 5) {
      setStep(step + 1);
    } else {
      handleSubmit({ preventDefault: () => {} });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        className="!bg-transparent !shadow-none border-2 border-gold px-8"
      >
        {buttonTitle}
      </Button>
      <Dialog
        size="xl"
        open={open}
        handler={handleOpen}
        className="bg-transparent shadow-none"
      >
        <Card className="mx-auto w-full max-w-xl">
          <CardBody className="flex flex-col gap-4">
            {!submitted ? (
              <>
                <Typography variant="h4" color="blue-gray">
                  {buttonTitle}
                </Typography>
                <form onSubmit={(e) => handleSubmit(e)}>
                  {step === 0 && (
                    <div>
                      <Typography variant="h6">
                        <FormattedMessage id="step0" />,
                      </Typography>
                      <div className="flex flex-col">
                        {[
                          intl.formatMessage({ id: "apartments" }),
                          intl.formatMessage({ id: "villas" }),
                          intl.formatMessage({ id: "townhouses" }),
                          intl.formatMessage({ id: "land" }),
                          intl.formatMessage({ id: "commercial" }),
                        ].map((option) => (
                          <Radio
                            key={option}
                            name="propertyType"
                            aria-label="Property"
                            value={option}
                            label={option}
                            onChange={handleChange}
                            checked={answers.propertyType === option}
                          />
                        ))}
                      </div>
                    </div>
                  )}

                  {step === 1 && (
                    <div>
                      <Typography variant="h6">
                        <FormattedMessage id="step1" />,
                      </Typography>
                      <div className="flex flex-col">
                        {[
                          intl.formatMessage({ id: "studio" }),
                          `1-${intl.formatMessage({ id: "bedroom" })}`,
                          `2-${intl.formatMessage({ id: "bedroom" })}`,
                          `3-${intl.formatMessage({ id: "bedroom" })}`,
                          `4-${intl.formatMessage({ id: "bedroom" })}`,
                          `5+ -${intl.formatMessage({ id: "bedrooms" })}`,
                        ].map((option) => (
                          <Checkbox
                            key={option}
                            aria-label="Studio"
                            name="sizePreference"
                            value={option}
                            label={option}
                            onChange={handleChange}
                            checked={answers.sizePreference.includes(option)}
                          />
                        ))}
                      </div>
                    </div>
                  )}

                  {step === 2 && (
                    <div>
                      <Typography variant="h6">
                        <FormattedMessage id="step2" />,
                      </Typography>
                      <div className="flex flex-col">
                        {[
                          intl.formatMessage({ id: "living" }),
                          intl.formatMessage({ id: "properties" }),
                          intl.formatMessage({ id: "communities" }),
                        ].map((option) => (
                          <Checkbox
                            key={option}
                            aria-label="Option"
                            name="areaType"
                            value={option}
                            label={option}
                            onChange={handleChange}
                            checked={answers.areaType.includes(option)}
                          />
                        ))}
                      </div>
                    </div>
                  )}

                  {step === 3 && (
                    <div>
                      <Typography variant="h6">
                        <FormattedMessage id="step3" />,
                      </Typography>
                      <div className="flex flex-col">
                        {[
                          intl.formatMessage({ id: "myself" }),
                          intl.formatMessage({ id: "investment" }),
                        ].map((option) => (
                          <Radio
                            key={option}
                            name="purpose"
                            value={option}
                            label={option}
                            onChange={handleChange}
                            checked={answers.purpose === option}
                          />
                        ))}
                      </div>
                    </div>
                  )}

                  {step === 4 && (
                    <div className="space-y-2 pb-4">
                      <Typography variant="h6">
                        <FormattedMessage id="step4" />
                      </Typography>
                      <Input
                        name="name"
                        label={intl.formatMessage({ id: "name" })}
                        size="lg"
                        aria-label="Name"
                        onChange={handleChange}
                        value={answers.name}
                        required
                      />
                      <Input
                        name="lastName"
                        label={intl.formatMessage({ id: "lastname" })}
                        size="lg"
                        aria-label="LastName"
                        onChange={handleChange}
                        value={answers.lastName}
                        required
                      />
                      <Input
                        name="phoneNumber"
                        label={intl.formatMessage({ id: "phone" })}
                        size="lg"
                        aria-label="Phone"
                        onChange={handleChange}
                        value={answers.phoneNumber}
                        required
                      />
                      <Input
                        name="email"
                        label={intl.formatMessage({ id: "email" })}
                        size="lg"
                        aria-label="EMail"
                        onChange={handleChange}
                        value={answers.email}
                        required
                      />
                    </div>
                  )}

                  {step < 4 ? (
                    <Button
                      variant=""
                      className="bg-gold text-white"
                      onClick={handleNext}
                      fullWidth
                      disabled={
                        (step === 0 && !answers.propertyType) ||
                        (step === 1 && answers.sizePreference.length === 0) ||
                        (step === 2 && answers.areaType.length === 0) ||
                        (step === 3 && !answers.purpose)
                      }
                    >
                      <FormattedMessage id="next" />
                    </Button>
                  ) : (
                    <Button type="submit" variant="gradient" fullWidth>
                      <FormattedMessage id="submit" />
                    </Button>
                  )}
                </form>
              </>
            ) : (
              <Typography variant="h6" className="text-gold">
                <FormattedMessage id="sc_message" />
              </Typography>
            )}
          </CardBody>
        </Card>
      </Dialog>
    </>
  );
}
