import { useState } from "react";
import { Lightbox } from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

export default function Gallery({ data }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className="grid grid-cols-1 max-sm:gap-y-4 sm:grid-cols-2 sm:gap-x-12 sm:gap-y-8 md:grid-cols-3 cursor-pointer">
        {data.map((e, i) => {
          return (
            <img
              src={e}
              alt="Images"
              onClick={() => setOpen(true)}
              // className={community.image_style}
              key={i}
            />
          );
        })}
        <Lightbox
          open={open}
          close={() => setOpen(false)}
          slides={data.map((url) => ({ src: url }))}
        />
      </div>
    </>
  );
}
