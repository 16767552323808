import Contact from "../Components/Contact";
import HomeBanner from "../Components/HomeBanner";
import HomeLuxury from "../Components/HomeLuxury";
import Insights from "../Components/Insights";
import ReactLenis from "@studio-freight/react-lenis";

export default function Home() {
  return (
    <>
      <ReactLenis root options={{ duration: 4 }}>
        <HomeBanner path="home" />
        <HomeLuxury />
        <Contact />
        <Insights />
      </ReactLenis>
    </>
  );
}
