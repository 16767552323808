import { useContext, useEffect, useState } from "react";
import { Context } from "react-intl/src/components/injectIntl";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import BadLogo from "../assets/images/icons/BedIcon.png";
import BathLogo from "../assets/images/icons/Bathub.png";
import Gallery from "../Components/Gallery";
import LocationLogo from "../assets/images/icons/location.png";
import MapComponent from "../Components/MapComponent";
import SqrtLogo from "../assets/images/icons/Sqrt.png";
import StandardContainer from "../Components/StandardContainer";
import ReactLenis from "@studio-freight/react-lenis";

export default function Rent() {
  const { slug } = useParams();

  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);
  const { locale } = useContext(Context);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/rents`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setItems(data.find((item) => item.slug == slug));
      } catch (error) {
        setError(error);
      } finally {
      }
    };
    fetchVideos();
  }, []);

  const content = (
    <>
      <div className="flex flex-col lg:flex-row gap-8">
        <div className="lg:w-1/2">
          {items.gallery_images && items.gallery_images.length > 0 ? (
            <img
              src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${items.gallery_images[0]}`}
              alt={items.title}
              className="w-full h-full object-cover"
            />
          ) : (
            <img
              src="https://www.apartments.com/blog/sites/default/files/styles/large/public/image/2023-06/the-huntley-atlanta-ga-luxury-apartment-view.jpg?itok=l26TswZZ"
              alt=""
            />
          )}
        </div>
        <div className="lg:w-1/2 flex flex-col gap-y-3 justify-between">
          <div className="grid gap-4 grid-cols-1 sm:grid-cols-2">
            <div className="">
              <div className="text-white text-xl font-normal">
                {items[`building_${locale}`]}
              </div>
            </div>
            <div className="flex gap-8">
              <div>
                <img src={LocationLogo} alt="" />
              </div>
              <div className="text-white text-xl font-normal">
                {items[`location_${locale}`]}
              </div>
            </div>
            <div className="text-white font-normal capitalize">
              {items.unit_type == "apartment" && (
                <FormattedMessage id="apartments" />
              )}
              {items.unit_type == "penthouses" && (
                <FormattedMessage id="penthouses" />
              )}
              {items.unit_type == "villa" && <FormattedMessage id="villas" />}
              {items.unit_type == "townhouse" && (
                <FormattedMessage id="townhouses" />
              )}
              {items.unit_type == "duplex" && <FormattedMessage id="duplex" />}
              {items.unit_type == "bulk_unit_rent" && (
                <FormattedMessage id="bulk_unit_rent" />
              )}
              {items.unit_type == "compound" && (
                <FormattedMessage id="compound" />
              )}
            </div>
            <div className="flex gap-8">
              <div>
                <img src={BadLogo} alt="" />
              </div>
              <div className="text-white text-xl font-normal">
                {items.bedrooms_number}
              </div>
            </div>
            <div className="flex gap-8">
              <div>
                <img src={BathLogo} alt="" />
              </div>
              <div className="text-white text-xl font-normal">
                {items.bathrooms_number}
              </div>
            </div>
            <div className="flex gap-8">
              <div>
                <img src={SqrtLogo} alt="" />
              </div>
              <div className="text-white text-xl font-normal">
                {items.size} <FormattedMessage id="sqft" />
              </div>
            </div>
          </div>
          <div className="text-white py-2 bg-gold text-center font-bold">
            <span className="pr-[1px]">$</span> {items.price} /{" "}
            <FormattedMessage id="month" />
          </div>
          <div>
            {items.qr_code ? (
              // <img
              // src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${items.qr_code}`}
              // />
              <img src="https://quickchart.io/qr?text=BarcodesInc&size=200" />
            ) : (
              <img src="https://quickchart.io/qr?text=BarcodesInc&size=200" />
            )}
          </div>
        </div>
      </div>
      <div className="my-12">
        <div
          className="text-white/70 text-lg text-justify sm:text-lg font-normal"
          dangerouslySetInnerHTML={{
            __html: items[`text_${locale}`],
          }}
        />
      </div>
      <div className="my-12">
        {items.gallery_images && items.gallery_images.length > 0 ? (
          <Gallery data={items.gallery_images} />
        ) : (
          <div className="">
            <Gallery
              data={[
                "https://www.apartments.com/blog/sites/default/files/styles/large/public/image/2023-06/the-huntley-atlanta-ga-luxury-apartment-view.jpg?itok=l26TswZZ",
                "https://www.apartments.com/blog/sites/default/files/styles/large/public/image/2023-06/the-huntley-atlanta-ga-luxury-apartment-view.jpg?itok=l26TswZZ",
                "https://www.apartments.com/blog/sites/default/files/styles/large/public/image/2023-06/the-huntley-atlanta-ga-luxury-apartment-view.jpg?itok=l26TswZZ",
                "https://www.apartments.com/blog/sites/default/files/styles/large/public/image/2023-06/the-huntley-atlanta-ga-luxury-apartment-view.jpg?itok=l26TswZZ",
                "https://www.apartments.com/blog/sites/default/files/styles/large/public/image/2023-06/the-huntley-atlanta-ga-luxury-apartment-view.jpg?itok=l26TswZZ",
              ]}
            />
          </div>
        )}
      </div>
      <div className="my-12 w-full ">
        <MapComponent address={items.building_en} />
      </div>
    </>
  );

  return (
    <ReactLenis root options={{ duration: 4 }}>
      <StandardContainer
        content={content}
        bgColor="py-12 sm:py-24 px-6 xl:px-0"
      />
    </ReactLenis>
  );
}
