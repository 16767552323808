import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Collapse,
  IconButton,
  Navbar,
  Typography,
  Input,
} from "@material-tailwind/react";
import { IoClose, IoSearch } from "react-icons/io5";
import { FaBars, FaBookmark, FaRegBookmark } from "react-icons/fa";
import { FormattedMessage } from "react-intl";

import Lang from "./Multilanguage/SetLang";
import Logo from "../assets/images/logo/logo.webp";
import StandardContainer from "./StandardContainer";
import WhatsUpIcon from "../assets/images/icons/whatsUpIcon.webp";
import { Context } from "./Multilanguage/Wrapper";

export default function Header() {
  const [openNav, setOpenNav] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);
  const { locale } = useContext(Context);

  const [wishlistCount, setWishlistCount] = useState(0);

  useEffect(() => {
    const updateWishlistCount = () => {
      const wishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
      setWishlistCount(wishlist.length);
    };

    updateWishlistCount();

    // Listen for the custom event
    const handleWishlistChange = (event) => {
      const wishlist = event.detail.wishlist;
      setWishlistCount(wishlist.length);
      localStorage.setItem("wishlist", JSON.stringify(wishlist));
    };

    window.addEventListener("wishlistChange", handleWishlistChange);

    return () => {
      window.removeEventListener("wishlistChange", handleWishlistChange);
    };
  }, []);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/contacts`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setItems(data[0]);
      } catch (error) {
        setError(error);
      }
    };
    fetchVideos();
  }, []);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  const [query, setQuery] = useState("");
  const navigate = useNavigate();

  const handleSearch = () => {
    navigate(`/search?query=${query}`);
    setOpenSearch(!openSearch);
  };

  const list = [
    {
      href: "/",
      text: <FormattedMessage id="menu_home" />,
    },
    {
      href: "/about",
      text: <FormattedMessage id="menu_about" />,
    },
    {
      href: "/buy",
      text: <FormattedMessage id="menu_buy" />,
    },
    // {
    //   href: "/rents",
    //   text: <FormattedMessage id="menu_rent" />,
    // },
    {
      href: "/sell",
      text: <FormattedMessage id="menu_sell" />,
    },
    {
      href: "/insights",
      text: <FormattedMessage id="menu_insights" />,
    },
    {
      href: "/projects",
      text: <FormattedMessage id="menu_projects" />,
    },
  ];

  const navList = (
    <ul className="mt-2 mb-4 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
      {list.map((item, index) => (
        <Typography
          as="li"
          variant="small"
          color="white"
          className="font-link-font text-base font-normal"
          key={index}
        >
          <Link
            to={item.href}
            className="flex items-center transition-all duration-300 hover:text-gold lg:hover:scale-110"
            onClick={() => setOpenNav(false)}
          >
            {item.text}
          </Link>
        </Typography>
      ))}
    </ul>
  );

  const content = (
    <>
      <Navbar
        id="Navbar"
        className=" top-0 z-10  max-w-full px-0  sm:px-4 py-3 lg:px-8 lg:py-4 shadow-none"
      >
        <div className="flex items-center justify-between text-blue-gray-900">
          <Link to="/">
            <img src={Logo} alt="Logo" />
          </Link>
          <div className="flex items-center gap-4">
            <div className="mr-4 hidden lg:block">{navList}</div>
            <div className="lg:hidden flex items-center gap-x-4">
              <Lang />
              {items && items.whatsup && (
                <a
                  href={items.whatsup}
                  aria-label="WhatsUp"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={WhatsUpIcon} alt="Whatsup Icon" />
                </a>
              )}
              <IoSearch
                size={24}
                className="text-gold cursor-pointer"
                onClick={() => setOpenSearch(!openSearch)}
              />
              <div className="relative">
                <Link to="/wishlist">
                  <FaRegBookmark size={20} className="text-gold" />
                  <span className="absolute -top-2 text-md text-gold -right-3 sm:-right-2">
                    {wishlistCount}
                  </span>
                </Link>
              </div>
            </div>
            <IconButton
              variant="text"
              className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
              ripple={false}
              onClick={() => setOpenNav(!openNav)}
            >
              {openNav ? (
                <IoClose className="text-gold text-xl" />
              ) : (
                <FaBars className="text-gold text-xl" />
              )}
            </IconButton>
          </div>
          <div className="hidden lg:flex lg:gap-4 items-center gap-x-1">
            <Lang />
            {items && items.whatsup && (
              <a
                href={items.whatsup}
                aria-label="WhatsUp"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={WhatsUpIcon}
                  className="h-6 mt-1"
                  alt="Whatsup Icon"
                />
              </a>
            )}
            <IoSearch
              size={26}
              className="text-gold mt-1 cursor-pointer"
              onClick={() => setOpenSearch(!openSearch)}
            />
            <div>
              <Link to="/wishlist">
                <FaRegBookmark size={20} className="text-gold" />
                <span className="absolute top-2 text-md text-gold right-4">
                  {wishlistCount}
                </span>
              </Link>
            </div>
          </div>
        </div>
        <Collapse open={openNav}>{navList}</Collapse>
      </Navbar>
      <div
        className={`${
          openSearch ? "h-16" : "h-0"
        } bg-black overflow-hidden  transition-all duration-300 `}
      >
        <div className="group relative py-2 px-4">
          <Input
            type="email"
            placeholder="...."
            aria-label="Search"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            className="focus:!border-gold text-gold group-hover:!border-gold group-hover:border-2"
            labelProps={{
              className: "hidden",
            }}
          />
          <div className="absolute top-[calc(50%-1px)] right-6 -translate-y-2/4">
            <kbd
              onClick={handleSearch}
              className="rounded border border-blue-gray-100 bg-white px-2 py-0  text-lg font-bold text-gold shadow shadow-black/5 cursor-pointer"
            >
              <FormattedMessage id="search" />
            </kbd>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <StandardContainer
      content={content}
      bgColor="bg-black md:fixed top-0 w-full z-50"
    />
  );
}
