import { useIntl, FormattedMessage } from "react-intl";
import { Button, Input, Textarea } from "@material-tailwind/react";
import StandardContainer from "./StandardContainer";
import Title from "./Title";

export default function Contact() {
  const intl = useIntl();
  const content = (
    <>
      <div className="py-12 px-6 xl:px-0">
        <div className="md:py-12 flex flex-col space-y-4 md:space-y-0 md:flex-row justify-between items-center">
          <div className="w-full md:w-2/5 border-[1px] border-white"></div>
          <div className="md:w-1/5 text-center font-normal text-2xl lg:text-3xl text-white">
            <Title text={<FormattedMessage id="contact_us" />} />
          </div>
          <div className="w-full md:w-2/5 border-[1px] border-white"></div>
        </div>
        <div className="mt-12 md:mt-0">
          <form action="">
            <div className="inputs grid grid-cols-1 sm:grid-cols-2 gap-8">
              <div className="flex flex-col space-y-8">
                <Input
                  variant="standard"
                  label={intl.formatMessage({ id: "name" })}
                  className="text-white"
                  name="name"
                  type="text"
                  aria-label="Name"
                />
                <Input
                  variant="standard"
                  label={intl.formatMessage({ id: "phone" })}
                  className="text-white"
                  name="phone"
                  type="number"
                  aria-label="Phone"
                />
                <Input
                  variant="standard"
                  label={intl.formatMessage({ id: "email" })}
                  className="text-white"
                  name="email"
                  type="email"
                  aria-label="Email"
                />
              </div>
              <div className="flex flex-col justify-between">
                <Textarea
                  label={intl.formatMessage({ id: "message" })}
                  aria-label="Message"
                />
                <Button variant="outlined" className="mt-12 sm:mt-0">
                  <FormattedMessage id="send" />
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
  return <StandardContainer content={content} />;
}
