import React, { useContext } from "react";
import { Typography } from "@material-tailwind/react";
import { Context } from "./Multilanguage/Wrapper";

export default function Description({
  dangerouslySetInnerHTML,
  dangerousContent,
  content,
  styles,
}) {
  const { locale } = useContext(Context);
  return (
    <>
      {dangerousContent ? (
        <Typography
          dir={locale == "ar" ? "rtl" : ""}
          as="p"
          className={styles}
          dangerouslySetInnerHTML={dangerousContent}
        />
      ) : (
        <Typography
          dir={locale == "ar" ? "rtl" : ""}
          as="div"
          className={styles}
        >
          {content}
        </Typography>
      )}
    </>
  );
}
