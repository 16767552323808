import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import CardItem from "../Components/CardItem";
import StandardContainer from "../Components/StandardContainer";
import { Context } from "../Components/Multilanguage/Wrapper";
import AppartmentLogo from "../assets/images/icons/apartment.png";
import { FormattedMessage } from "react-intl";
import Description from "../Components/Description";
import Title from "../Components/Title";

export default function Result() {
  const [results, setResults] = useState({
    about: [],
    sells: [],
    // sells1: [],
    // rents: [],
    projects: [],
    insights: [],
  });
  const location = useLocation();
  const query = new URLSearchParams(location.search).get("query");

  const { locale } = useContext(Context);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://admin.agencyone.ae/search", {
          params: { query },
        });
        setResults(response.data);
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    };

    if (query) {
      fetchData();
    }
  }, [query]);
  console.log(results);
  const content = (
    <>
      {results.sells.length > 0 && (
        <div className="grid  grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 my-8">
          {results.sells &&
            results.sells.map((item, index) => {
              return (
                <CardItem key={index} data={item} pagePath="buy" path="home" />
              );
            })}
        </div>
      )}
      {results.projects.length > 0 && (
        <div className="grid  grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 my-8">
          {results.projects &&
            results.projects.map((item, index) => {
              return (
                <div
                  key={index}
                  className="rounded-lg border-[1px] border-gold overflow-hidden"
                >
                  <Link to={`/project/${item.slug}`}>
                    <div className="h-72">
                      <img
                        src="https://cf.bstatic.com/xdata/images/hotel/max1024x768/425755208.jpg?k=617409aaddaf4b9d3ae02e6d9e0990902f470bfe78ec5fd61b628059b72ca376&o=&hp=1"
                        alt=""
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <div className="p-4 text-white font-normal capitalize">
                      {item[`title_${locale}`]}
                    </div>
                    <div className="flex justify-between p-4">
                      <div className="flex gap-4">
                        <div>
                          <img src={AppartmentLogo} alt="" />
                        </div>
                        <div className="text-white font-normal capitalize">
                          {item.unit_type == "apartment" && (
                            <FormattedMessage id="apartments" />
                          )}
                          {item.unit_type == "penthouses" && (
                            <FormattedMessage id="penthouses" />
                          )}
                          {item.unit_type == "villa" && (
                            <FormattedMessage id="villas" />
                          )}
                          {item.unit_type == "townhouse" && (
                            <FormattedMessage id="townhouses" />
                          )}
                          {item.unit_type == "duplex" && (
                            <FormattedMessage id="duplex" />
                          )}
                          {item.unit_type == "bulk_unit_rent" && (
                            <FormattedMessage id="bulk_unit_rent" />
                          )}
                          {item.unit_type == "compound" && (
                            <FormattedMessage id="compound" />
                          )}
                        </div>
                      </div>
                      <div>
                        <div></div>
                        <div className="text-white font-normal capitalize">
                          {item.plan == 0 ? (
                            <FormattedMessage id="off-plan" />
                          ) : (
                            <FormattedMessage id="ready" />
                          )}
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
        </div>
      )}
      {results.insights.length > 0 && (
        <div className="grid  grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 my-8">
          {results.insights &&
            results.insights.map((item, index) => {
              return (
                <div
                  key={index}
                  className="rounded-lg border-[1px] border-gold overflow-hidden"
                >
                  <Link to={`/insight/${item.slug}`}>
                    <div className="h-72">
                      <img
                        src="https://cf.bstatic.com/xdata/images/hotel/max1024x768/425755208.jpg?k=617409aaddaf4b9d3ae02e6d9e0990902f470bfe78ec5fd61b628059b72ca376&o=&hp=1"
                        alt=""
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <Title
                      text={item[`title_${locale}`]}
                      styles="p-4 text-white font-normal capitalize"
                    />
                  </Link>
                </div>
              );
            })}
        </div>
      )}
      {results.about.length > 0 && (
        <div className="my-8">
          {results.about &&
            results.about.map((item, index) => {
              return (
                <div
                  className={`flex flex-col gap-8 py-12 sm:py-24 px-6 xl:px-0 ${
                    index % 2 !== 0 ? "lg:flex-row-reverse" : "lg:flex-row"
                  }`}
                  key={index}
                >
                  <div className="lg:w-1/2 flex flex-col justify-evenly">
                    <Description
                      styles="text-white/70 text-lg  sm:text-lg font-normal"
                      // className="text-white/70 text-lg  sm:text-lg font-normal"
                      dangerousContent={{
                        __html: item[`description_${locale}`],
                      }}
                    />
                  </div>
                  <div className="lg:w-1/2 mt-12 lg:mt-0">
                    <img
                      src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${item.cover_image}`}
                      alt={item.description_en}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      )}
      {results.about.length > 0 && (
        <div className="my-8">
          {results.about &&
            results.about.map((item, index) => {
              if (item.location == "sell") {
                return (
                  <div
                    className={`flex flex-col gap-8 py-12 sm:py-24 px-6 xl:px-0 ${
                      index % 2 !== 0 ? "lg:flex-row-reverse" : "lg:flex-row"
                    }`}
                    key={index}
                  >
                    <div className="lg:w-1/2 flex flex-col justify-evenly">
                      <Description
                        styles="text-white/70 text-lg  sm:text-lg font-normal"
                        // className="text-white/70 text-lg  sm:text-lg font-normal"
                        dangerousContent={{
                          __html: item[`description_${locale}`],
                        }}
                      />
                    </div>
                    <div className="lg:w-1/2 mt-12 lg:mt-0">
                      <img
                        src={`https://admin.agencyone.ae/uploads/${item.cover_image}`}
                        alt={item.description_en}
                      />
                    </div>
                  </div>
                );
              }
            })}
        </div>
      )}
      {/* {results.rents.length > 0 && (
        <div className="grid grid-cols-4 gap-8">
          {results.rents &&
            results.rents.map((item, index) => {
              return (
                <div key={index}>
                  <div className="h-48">
                    <img
                      src="https://cf.bstatic.com/xdata/images/hotel/max1024x768/425755208.jpg?k=617409aaddaf4b9d3ae02e6d9e0990902f470bfe78ec5fd61b628059b72ca376&o=&hp=1"
                      alt=""
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div>{item.title}</div>
                  <div>{item.unit_type}</div>
                  <div>{item.bedrooms_number}</div>
                  <div>
                    <Link to="" className="font-bold text-blue-500">
                      See More
                    </Link>
                  </div>
                </div>
              );
            })}
        </div>
      )} */}
    </>
  );

  return (
    <>
      <StandardContainer content={content} bgColor="py-12 sm:py-24" />
    </>
  );
}
