import { useState, useEffect, useContext } from "react";
import StandardContainer from "./StandardContainer";
import { FormattedMessage } from "react-intl";
import { Context } from "./Multilanguage/Wrapper";
import { Link } from "react-router-dom";
import Title from "./Title";
import Description from "./Description";

export default function Insights() {
  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);
  const { locale } = useContext(Context);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/insights`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setItems(data);
      } catch (error) {
        setError(error);
      } finally {
      }
    };

    fetchItems();
  }, []);
  const content = (
    <>
      <div className="py-8 px-6 xl:px-0">
        <div className="text-white text-center font-bold text-3xl">
          <FormattedMessage id="menu_insights" />
        </div>
        <div className="my-12 flex flex-col lg:flex-row gap-4">
          <div className="lg:w-2/3 grid grid-cols-1 md:grid-cols-2 gap-6">
            {items &&
              items.slice(0, 2).map((item, index) => {
                return (
                  <div key={index}>
                    <div className="h-64 rounded-lg overflow-hidden">
                      <img
                        src="https://www.bbvaapimarket.com/wp-content/uploads/2018/04/blogsapis.jpg"
                        className="w-full h-full object-cover"
                        alt={item.title_en}
                      />
                    </div>
                    <div className="mt-8">
                      <div className="text-white h-16 font-normal font-link-font text-lg line-clamp-2 overflow-hidden">
                        <Title text={item[`title_${locale}`]} />
                      </div>
                      <div className="text-white/50 h-20 font-normal font-link-font text-sm line-clamp-3 overflow-hidden">
                        <Description content={item[`text_${locale}`]} />
                      </div>
                      <div className="mt-6">
                        <Link
                          to={`/insight/${item.slug}`}
                          className="border-[1px] border-gold rounded-sm p-2 font-bold font-link-font text-gold"
                          aria-label={`Read More About ${item.slug}`}
                        >
                          <FormattedMessage id="read_more" />
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="mt-12 lg:mt-0 lg:w-1/3 flex flex-col space-y-6">
            {items &&
              items.slice(2, 5).map((item, index) => {
                return (
                  <div key={index} className="flex flex-col sm:flex-row gap-4">
                    <div className="sm:w-1/3 sm:h-32 border-2 rounded-lg overflow-hidden">
                      <img
                        src="https://www.bbvaapimarket.com/wp-content/uploads/2018/04/blogsapis.jpg"
                        className="w-full h-full object-cover"
                        alt={item.title_en}
                      />
                    </div>
                    <div className="w-2/3 flex flex-col justify-between">
                      <div className="text-white font-normal font-link-font text-lg line-clamp-2 overflow-hidden">
                        <Title text={item[`title_${locale}`]} />
                      </div>
                      <div className="text-white/50 font-normal font-link-font text-sm line-clamp-2 overflow-hidden">
                        <Description content={item[`text_${locale}`]} />
                      </div>
                      <div className="mt-8 sm:mt-0">
                        <Link
                          to={`/insight/${item.slug}`}
                          aria-label={`Read More About ${item.slug}`}
                          className="border-[1px] border-gold rounded-sm p-2 font-bold font-link-font text-gold"
                        >
                          <FormattedMessage id="read_more" />
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
  return <StandardContainer content={content} />;
}
