import { Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import Result from "./Pages/Result";
import Search from "./Components/Search";
import Lang from "./Components/Multilanguage/SetLang";
import Rents from "./Pages/Rents";
import Insights from "./Pages/Insights";
import Projects from "./Pages/Projects";
import Header from "./Components/Header";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import About from "./Pages/About";
import Footer from "./Components/Footer";
import ScrollToTop from "./Components/ScrollTop";
import Rent from "./Pages/Rent";
import "leaflet/dist/leaflet.css";
import Buy from "./Pages/Buy";
import BuySingle from "./Pages/BuySingle";
import Sell from "./Pages/Sell";
import Insight from "./Pages/Insight";
import Project from "./Pages/Project";
import Wishlist from "./Pages/WishList";

function App() {
  return (
    <>
      <ScrollToTop />

      <Header />
      {/* <Search /> */}
      {/* <Lang /> */}
      <Routes>
        <Route path="" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/sell" element={<Sell />} />
        <Route path="/rents" element={<Rents />} />
        <Route path="/rent/:slug" element={<Rent />} />
        <Route path="/buy" element={<Buy />} />
        <Route path="/buy/:slug" element={<BuySingle />} />
        <Route path="/insights" element={<Insights />} />
        <Route path="/insight/:slug" element={<Insight />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/project/:slug" element={<Project />} />
        <Route path="/search" element={<Result />} />
        <Route path="/wishlist" element={<Wishlist />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
