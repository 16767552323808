import { Typography } from "@material-tailwind/react";
import { useContext } from "react";
import { Context } from "./Multilanguage/Wrapper";

export default function Title({ head, text, styles }) {
  const { locale } = useContext(Context);

  return (
    <>
      <Typography
        dir={locale == "ar" ? "rtl" : ""}
        variant={head ? "h1" : "paragraph"}
        className={styles}
      >
        {text}
      </Typography>
    </>
  );
}
