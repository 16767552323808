import { useContext, useEffect, useState } from "react";
import { Context } from "react-intl/src/components/injectIntl";

import StandardContainer from "../Components/StandardContainer";
import HomeBanner from "../Components/HomeBanner";
import { FormattedMessage } from "react-intl";
import ReactLenis from "@studio-freight/react-lenis";
import Title from "../Components/Title";
import Description from "../Components/Description";

export default function About() {
  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);
  const { locale } = useContext(Context);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await fetch(`https://admin.agencyone.ae/api/about`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setItems(data);
      } catch (error) {
        setError(error);
      } finally {
      }
    };
    fetchVideos();
  }, []);
  const content = (
    <>
      {items &&
        items.map((item, index) => {
          return (
            <div
              className={`flex flex-col gap-8 py-12 sm:py-24 px-6 xl:px-0 ${
                index % 2 !== 0 ? "lg:flex-row-reverse" : "lg:flex-row"
              }`}
              key={index}
            >
              <div className="lg:w-1/2 flex flex-col justify-evenly">
                {index % 2 == 0 && (
                  <Title
                    styles="text-white font-normal text-3xl"
                    text={<FormattedMessage id="menu_about" />}
                  />
                )}
                <Description
                  styles="text-white/70 text-lg  sm:text-lg font-normal"
                  // className="text-white/70 text-lg  sm:text-lg font-normal"
                  dangerousContent={{
                    __html: item[`description_${locale}`],
                  }}
                />
              </div>
              <div className="lg:w-1/2 mt-12 lg:mt-0">
                <img
                  src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${item.cover_image}`}
                  alt={item.description_en}
                />
              </div>
            </div>
          );
        })}
    </>
  );
  return (
    <>
      <ReactLenis root options={{ duration: 4 }}>
        <HomeBanner path="about" />
        <StandardContainer content={content} />;
      </ReactLenis>
    </>
  );
}
