import { useContext, useEffect, useState } from "react";
import { Context } from "react-intl/src/components/injectIntl";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import StandardContainer from "../Components/StandardContainer";
import Gallery from "../Components/Gallery";

import AppartmentLogo from "../assets/images/icons/apartment.png";

import ReactLenis from "@studio-freight/react-lenis";

export default function Project() {
  const { slug } = useParams();

  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);
  const { locale } = useContext(Context);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/projects`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setItems(data.find((item) => item.slug == slug));
      } catch (error) {
        setError(error);
      } finally {
      }
    };
    fetchVideos();
  }, []);
  const content = (
    <>
      <div className="flex flex-col lg:flex-row gap-8">
        <div className="lg:w-1/2">
          {items && items.cover_image ? (
            <img
              src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${items.cover_image}`}
              alt={items.title_en}
              className="w-full h-full object-cover"
            />
          ) : (
            <img
              src="https://www.apartments.com/blog/sites/default/files/styles/large/public/image/2023-06/the-huntley-atlanta-ga-luxury-apartment-view.jpg?itok=l26TswZZ"
              alt=""
            />
          )}
        </div>
        <div className="lg:w-1/2 flex flex-col gap-y-3 justify-between">
          <div>
            <div className="text-white font-normal text-3xl">
              {items[`title_${locale}`]}
            </div>
            <div className="text-white font-normal text-xl">
              {items.developer}
            </div>
          </div>
          <div
            className="text-white/70 text-lg text-justify sm:text-lg font-normal"
            dangerouslySetInnerHTML={{
              __html: items[`text_${locale}`],
            }}
          />
          <div className="flex justify-between gap-8">
            <div className="flex gap-4">
              <div className="flex gap-4">
                <img src={AppartmentLogo} alt="" />
              </div>
              <div className="text-white font-normal capitalize">
                {items.unit_type == "apartment" && (
                  <FormattedMessage id="apartments" />
                )}
                {items.unit_type == "penthouses" && (
                  <FormattedMessage id="penthouses" />
                )}
                {items.unit_type == "villa" && <FormattedMessage id="villas" />}
                {items.unit_type == "townhouse" && (
                  <FormattedMessage id="townhouses" />
                )}
                {items.unit_type == "duplex" && (
                  <FormattedMessage id="duplex" />
                )}
                {items.unit_type == "bulk_unit_rent" && (
                  <FormattedMessage id="bulk_unit_rent" />
                )}
                {items.unit_type == "compound" && (
                  <FormattedMessage id="compound" />
                )}
              </div>
            </div>
            <div className="text-white font-normal capitalize">
              {items.plan == 0 ? (
                <FormattedMessage id="off-plan" />
              ) : (
                <FormattedMessage id="ready" />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="my-12">
        {items.gallery_images && items.gallery_images.length > 0 ? (
          <Gallery data={items.gallery_images} />
        ) : (
          <div className="">
            <Gallery
              data={[
                "https://www.apartments.com/blog/sites/default/files/styles/large/public/image/2023-06/the-huntley-atlanta-ga-luxury-apartment-view.jpg?itok=l26TswZZ",
                "https://www.apartments.com/blog/sites/default/files/styles/large/public/image/2023-06/the-huntley-atlanta-ga-luxury-apartment-view.jpg?itok=l26TswZZ",
                "https://www.apartments.com/blog/sites/default/files/styles/large/public/image/2023-06/the-huntley-atlanta-ga-luxury-apartment-view.jpg?itok=l26TswZZ",
                "https://www.apartments.com/blog/sites/default/files/styles/large/public/image/2023-06/the-huntley-atlanta-ga-luxury-apartment-view.jpg?itok=l26TswZZ",
                "https://www.apartments.com/blog/sites/default/files/styles/large/public/image/2023-06/the-huntley-atlanta-ga-luxury-apartment-view.jpg?itok=l26TswZZ",
              ]}
            />
          </div>
        )}
      </div>
    </>
  );
  return (
    <ReactLenis root options={{ duration: 4 }}>
      <StandardContainer
        content={content}
        bgColor="py-12 sm:py-24 px-6 sm:px-0"
      />
    </ReactLenis>
  );
}
