import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import LoadingIndicator from "../Components/LoadingIndicator";
import StandardContainer from "../Components/StandardContainer";
import { FormattedMessage } from "react-intl";
import { Context } from "../Components/Multilanguage/Wrapper";
import { Select, Option } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import AppartmentLogo from "../assets/images/icons/apartment.png";
import HomeBanner from "../Components/HomeBanner";
import ReactLenis from "@studio-freight/react-lenis";

export default function Projects() {
  const [allItems, setAllItems] = useState([]);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const itemsPerPage = 15;
  const [page, setPage] = useState(1);
  const [plan, setPlan] = useState("");

  const { locale } = useContext(Context);

  useEffect(() => {
    fetchItems();
  }, [plan]);

  useEffect(() => {
    paginateItems();
  }, [allItems, page]);

  const fetchItems = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://admin.agencyone.ae/api/projects"
      );
      let filteredItems = response.data;

      if (plan) {
        filteredItems = filteredItems.filter(
          (item) => item.plan === parseInt(plan)
        );
      }

      setAllItems(filteredItems);
      setPage(1);
    } catch (error) {
      console.error("Error fetching items:", error);
      setAllItems([]);
    }
    setLoading(false);
  };

  const paginateItems = () => {
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setProjects(allItems.slice(startIndex, endIndex));
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleReset = () => {
    setPlan("");
    setPage(1);
  };

  const content = (
    <>
      <div className="px-6 xl:px-0 filters">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-x-8 gap-y-4 mt-1">
          <div className="">
            <Select
              label={<FormattedMessage id="plan" />}
              color="blue-gray"
              value={plan}
              onChange={(value) => setPlan(value)}
            >
              <Option value="">
                <FormattedMessage id="plan" />
              </Option>
              <Option value="0">
                <FormattedMessage id="off-plan" />
              </Option>
              <Option value="1">
                <FormattedMessage id="ready" />
              </Option>
            </Select>
          </div>
          <button
            className="border-[1px] capitalize border-gold text-gold px-6 py-1 sm:py-2 font-normal rounded-md"
            onClick={handleReset}
          >
            <FormattedMessage id="reset" />
          </button>
        </div>

        {loading ? (
          <LoadingIndicator message={<FormattedMessage id="search_text" />} />
        ) : (
          <div className="">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 my-12">
              {projects &&
                projects.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="rounded-lg border-[1px] border-gold overflow-hidden"
                    >
                      <Link to={`/project/${item.slug}`}>
                        <div className="h-72">
                          <img
                            src="https://cf.bstatic.com/xdata/images/hotel/max1024x768/425755208.jpg?k=617409aaddaf4b9d3ae02e6d9e0990902f470bfe78ec5fd61b628059b72ca376&o=&hp=1"
                            alt=""
                            className="w-full h-full object-cover"
                          />
                        </div>
                        <div className="p-4 text-white font-normal capitalize">
                          {item[`title_${locale}`]}
                        </div>
                        <div className="flex justify-between p-4">
                          <div className="flex gap-4">
                            <div>
                              <img src={AppartmentLogo} alt="" />
                            </div>
                            <div className="text-white font-normal capitalize">
                              {item.unit_type == "apartment" && (
                                <FormattedMessage id="apartments" />
                              )}
                              {item.unit_type == "penthouses" && (
                                <FormattedMessage id="penthouses" />
                              )}
                              {item.unit_type == "villa" && (
                                <FormattedMessage id="villas" />
                              )}
                              {item.unit_type == "townhouse" && (
                                <FormattedMessage id="townhouses" />
                              )}
                              {item.unit_type == "duplex" && (
                                <FormattedMessage id="duplex" />
                              )}
                              {item.unit_type == "bulk_unit_rent" && (
                                <FormattedMessage id="bulk_unit_rent" />
                              )}
                              {item.unit_type == "compound" && (
                                <FormattedMessage id="compound" />
                              )}
                            </div>
                          </div>
                          <div>
                            <div></div>
                            <div className="text-white font-normal capitalize">
                              {item.plan == 0 ? (
                                <FormattedMessage id="off-plan" />
                              ) : (
                                <FormattedMessage id="ready" />
                              )}
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
            </div>

            <div className="my-12 flex justify-end gap-4">
              {Array.from(
                { length: Math.ceil(allItems.length / itemsPerPage) },
                (_, index) => (
                  <button
                    key={index}
                    onClick={() => handlePageChange(index + 1)}
                    className={
                      page === index + 1
                        ? "active w-6 h-8 sm:w-8 sm:h-8 text-gold border-2 border-gold rounded-sm scale-125"
                        : "sm:w-8 w-6 h-8 sm:h-8 text-white border-2 border-white rounded-sm"
                    }
                  >
                    {index + 1}
                  </button>
                )
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );

  return (
    <>
      <ReactLenis root options={{ duration: 4 }}>
        <HomeBanner path="projects" />
        <StandardContainer
          content={content}
          bgColor="py-12 sm:py-24 px-6 sm:px-0"
        />
      </ReactLenis>
    </>
  );
}
