import React, { useState, useEffect, useContext } from "react";
import axios from "axios";

import LoadingIndicator from "../Components/LoadingIndicator";
import StandardContainer from "../Components/StandardContainer";
import { FormattedMessage } from "react-intl";
import { Context } from "../Components/Multilanguage/Wrapper";
import { Link } from "react-router-dom";
import HomeBanner from "../Components/HomeBanner";
import ReactLenis from "@studio-freight/react-lenis";
import Title from "../Components/Title";

export default function Insights() {
  const [allItems, setAllItems] = useState([]);
  const [insights, setInsights] = useState([]);
  const [loading, setLoading] = useState(true);
  const itemsPerPage = 15;
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);

  const { locale } = useContext(Context);

  useEffect(() => {
    fetchItems();
  }, []);

  useEffect(() => {
    paginateItems();
  }, [allItems, page]);

  const fetchItems = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://admin.agencyone.ae/api/insights"
      );
      setAllItems(response.data);
      setPage(1);
    } catch (error) {
      console.error("Error fetching items:", error);
      setAllItems([]);
    }
    setLoading(false);
  };

  const paginateItems = () => {
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setInsights(allItems.slice(startIndex, endIndex));
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const content = (
    <div>
      {loading ? (
        <LoadingIndicator message={<FormattedMessage id="search_text" />} />
      ) : (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {insights.map((item) => (
              <div
                key={item.id}
                className="rounded-lg border-[1px] border-gold overflow-hidden"
              >
                <Link to={`/insight/${item.slug}`}>
                  <div className="h-72">
                    <img
                      src="https://cf.bstatic.com/xdata/images/hotel/max1024x768/425755208.jpg?k=617409aaddaf4b9d3ae02e6d9e0990902f470bfe78ec5fd61b628059b72ca376&o=&hp=1"
                      alt=""
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <Title
                    text={item[`title_${locale}`]}
                    styles="p-4 text-white font-normal capitalize"
                  />
                </Link>
              </div>
            ))}
          </div>
          <div className="my-12 flex justify-end gap-4">
            {Array.from(
              { length: Math.ceil(allItems.length / itemsPerPage) },
              (_, index) => (
                <button
                  key={index}
                  onClick={() => handlePageChange(index + 1)}
                  className={
                    page === index + 1
                      ? "active w-6 h-8 sm:w-8 sm:h-8 text-gold border-2 border-gold rounded-sm scale-125"
                      : "sm:w-8 w-6 h-8 sm:h-8 text-white border-2 border-white rounded-sm"
                  }
                >
                  {index + 1}
                </button>
              )
            )}
          </div>
        </>
      )}
    </div>
  );
  return (
    <>
      <ReactLenis root options={{ duration: 4 }}>
        <HomeBanner path="insights" />
        <StandardContainer
          content={content}
          bgColor="py-12 sm:py-24 px-6 sm:px-0"
        />
      </ReactLenis>
    </>
  );
}
