import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import StandardContainer from "./StandardContainer";
import TabsAppartments from "./TabsAppartments";

import ShowLogo from "../assets/images/icons/ShowLogo.png";
import { useContext } from "react";
import { Context } from "./Multilanguage/Wrapper";

export default function HomeLuxury() {
  const { locale } = useContext(Context);
  const content = (
    <>
      <div className="py-24">
        <div
          dir={locale == "ar" ? "rtl" : ""}
          className={`text-white  font-normal text-3xl ${
            locale == "ar" ? "text-right" : "text-center"
          }`}
        >
          <FormattedMessage id="luxury_properties" />
        </div>
        <TabsAppartments />
        <div className="flex justify-center">
          <Link to="/buy" className="text-center">
            <div className="text-gold font-bold font-link-font">
              <FormattedMessage id="show_all" />
            </div>
            <div className="mt-4">
              <img src={ShowLogo} alt="" />
            </div>
          </Link>
        </div>
      </div>
    </>
  );
  return <StandardContainer content={content} />;
}
