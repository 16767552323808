import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import StandardContainer from "../Components/StandardContainer";

import { Context } from "./Multilanguage/Wrapper";
import { Typography } from "@material-tailwind/react";
import { FormattedMessage } from "react-intl";
import { MdPhone, MdEmail, MdLocationOn } from "react-icons/md";

import FaceIcon from "../assets/images/icons/face.png";
import InstaIcon from "../assets/images/icons/insta.png";
import WhatsIcon from "../assets/images/icons/whatsUpIcon.webp";
import Description from "./Description";
import Title from "./Title";

export default function Footer() {
  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);
  const { locale } = useContext(Context);

  const list = [
    {
      href: "/",
      text: <FormattedMessage id="menu_home" />,
    },
    {
      href: "/about",
      text: <FormattedMessage id="menu_about" />,
    },
    {
      href: "/buy",
      text: <FormattedMessage id="menu_buy" />,
    },
    {
      href: "/rent",
      text: <FormattedMessage id="menu_rent" />,
    },
    {
      href: "/sell",
      text: <FormattedMessage id="menu_sell" />,
    },
    {
      href: "/insights",
      text: <FormattedMessage id="menu_insights" />,
    },
  ];

  const navList = (
    <ul className="mt-2 mb-4 md:grid grid-cols-2 gap-2 lg:mb-0 lg:mt-0">
      {list &&
        list.map((item, index) => {
          return (
            <Typography
              as="li"
              variant="small"
              color="white"
              className="font-link-font text-base font-normal"
              key={index}
            >
              <Link
                to={item.href}
                dir={locale == "ar" ? "rtl" : ""}
                className={` ${
                  locale == "ar" ? "text-right" : "text-center"
                } block transition-all duration-300 hover:text-gold lg:hover:scale-110`}
              >
                {item.text}
              </Link>
            </Typography>
          );
        })}
    </ul>
  );

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/contacts`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setItems(data[0]);
      } catch (error) {
        setError(error);
      } finally {
      }
    };
    fetchVideos();
  }, []);
  const content = (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-12 px-6 xl:px-0">
        <div>
          <div>
            {items && items.cover_image && (
              <img
                src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${items.cover_image}`}
                alt=""
              />
            )}
          </div>
          {items && (
            <Description
              styles="text-white/70 mt-8 text-lg line-clamp-5 sm:text-lg font-normal"
              dangerouslySetInnerHTML
              dangerousContent={{
                __html: items[`description_${locale}`],
              }}
            />
          )}
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2">
          <div className="">
            <div
              className={`${
                locale == "ar" ? "text-right" : "text-center"
              } whitespace-nowrap text-center font-normal text-2xl lg:text-3xl text-white w-full`}
            >
              <Title text={<FormattedMessage id="quick_inks" />} />
            </div>
            <div className="mt-8">{navList}</div>
          </div>
          <div>
            <div
              className={`${
                locale == "ar" ? "text-right" : "text-center"
              } whitespace-nowrap text-center font-normal text-2xl lg:text-3xl text-white w-full`}
            >
              <Title text={<FormattedMessage id="contact_us" />} />
            </div>
            {items && (
              <>
                <div
                  dir={locale == "ar" ? "rtl" : ""}
                  className="mt-8 flex flex-col gap-2"
                >
                  {items.phone && (
                    <div className="flex items-center gap-4">
                      <div>
                        <MdPhone className="text-gold" size={24} />
                      </div>
                      <div>
                        <a
                          href={`tel:${items.phone}`}
                          className="text-gold text-lg font-bold"
                        >
                          {items.phone}
                        </a>
                      </div>
                    </div>
                  )}
                  {items.email && (
                    <div className="flex gap-4">
                      <div>
                        <MdEmail className="text-gold" size={24} />
                      </div>
                      <div>
                        <a
                          href={`mailto:${items.email}`}
                          className="text-gold text-lg font-bold"
                        >
                          {items.email}
                        </a>
                      </div>
                    </div>
                  )}
                  {(items.address_an ||
                    items.address_ru ||
                    items.address_ar) && (
                    <div className="flex gap-4">
                      <div>
                        <MdLocationOn className="text-gold" size={24} />
                      </div>
                      <div className="text-gold text-lg font-bold">
                        {items[`address_${locale}`]}
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className={`${
                    locale == "ar" ? "justify-end" : "justify-start"
                  } flex text-gold gap-4 mt-4`}
                >
                  {items.facebook && (
                    <div>
                      <a
                        href={items.facebook}
                        aria-label="Facebook"
                        target="_blank"
                      >
                        <img src={FaceIcon} alt="" />
                      </a>
                    </div>
                  )}
                  {items.instagram && (
                    <div>
                      <a
                        href={items.instagram}
                        aria-label="Instagram"
                        target="_blank"
                      >
                        <img src={InstaIcon} alt="" />
                      </a>
                    </div>
                  )}
                  {items.whatsup && (
                    <div>
                      <a
                        href={items.whatsup}
                        aria-label="WhatsUp"
                        target="_blank"
                      >
                        <img src={WhatsIcon} alt="" />
                      </a>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
  return <StandardContainer content={content} bgColor="bg-black py-12" />;
}
