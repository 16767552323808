import { MdOutlineSync } from "react-icons/md";

export default function LoadingIndicator({ message }) {
  return (
    <div
      className="fixed left-0 right-0 top-0 z-50 flex h-full w-full items-center justify-center bg-white/60"
      tabIndex="-1"
    >
      <div
        className="flex bg-black h-[20%] w-[40%] flex-col items-center justify-center gap-y-8 rounded-md bg-theme-silver p-4 text-center text-gold shadow-md sm:h-2/5 sm:w-1/4"
        role="status"
      >
        <MdOutlineSync className="shrink-0 animate-spin fill-gold" size="48" />
        <p className="animate-pulse text-lg font-semibold md:text-xl">
          {message}&hellip;
        </p>
      </div>
    </div>
  );
}
