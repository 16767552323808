import { FormattedMessage, useIntl } from "react-intl";
import { Range, getTrackBackground } from "react-range";
import { Select, Option } from "@material-tailwind/react";
import axios from "axios";
import CardItem from "../Components/CardItem";
import HomeBanner from "../Components/HomeBanner";
import LoadingIndicator from "../Components/LoadingIndicator";
import React, { useState, useEffect, useCallback } from "react";
import ReactLenis from "@studio-freight/react-lenis";
import StandardContainer from "../Components/StandardContainer";

const itemsPerPage = 15;

const useFilters = () => {
  const [filters, setFilters] = useState({
    unitType: localStorage.getItem("unitTypeRents") || "",
    bedroomsNumber: localStorage.getItem("bedroomsNumberRents") || "",
    priceRange: JSON.parse(localStorage.getItem("priceRangeRents")) || [],
    sizeRange: JSON.parse(localStorage.getItem("sizeRangeRents")) || [],
    furnished: localStorage.getItem("furnishedRents") || "",
    location: localStorage.getItem("locationRents") || "",
  });

  useEffect(() => {
    localStorage.setItem("unitTypeRents", filters.unitType);
    localStorage.setItem("bedroomsNumberRents", filters.bedroomsNumber);
    localStorage.setItem("priceRangeRents", JSON.stringify(filters.priceRange));
    localStorage.setItem("sizeRangeRents", JSON.stringify(filters.sizeRange));
    localStorage.setItem("furnishedRents", filters.furnished);
    localStorage.setItem("locationRents", filters.location);
  }, [filters]);

  return [filters, setFilters];
};

export default function Rents() {
  const [allItems, setAllItems] = useState([]);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useFilters();
  const [minMaxPrice, setMinMaxPrice] = useState([0, 1000000000]);
  const [minMaxSize, setMinMaxSize] = useState([0, 10000]);
  const [locations, setLocations] = useState([]);
  const intl = useIntl();
  const locale = intl.locale;
  const [tempPriceRange, setTempPriceRange] = useState(filters.priceRange);
  const [tempSizeRange, setTempSizeRange] = useState(filters.sizeRange);
  const [initialFetchDone, setInitialFetchDone] = useState(false);

  const fetchItems = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get("https://admin.agencyone.ae/api/rents");
      let filteredItems = response.data;

      if (filters.unitType) {
        filteredItems = filteredItems.filter(
          (item) => item.unit_type === filters.unitType
        );
      }

      if (filters.bedroomsNumber) {
        if (filters.bedroomsNumber === "5+") {
          filteredItems = filteredItems.filter(
            (item) => item.bedrooms_number > 5
          );
        } else {
          filteredItems = filteredItems.filter(
            (item) => item.bedrooms_number == parseInt(filters.bedroomsNumber)
          );
        }
      }

      const prices = filteredItems.map((item) => item.price);
      const minPrice = Math.min(...prices);
      const maxPrice = Math.max(...prices);
      setMinMaxPrice([minPrice, maxPrice]);

      const sizes = filteredItems.map((item) => item.size);
      const minSize = Math.min(...sizes);
      const maxSize = Math.max(...sizes);
      setMinMaxSize([minSize, maxSize]);

      if (!initialFetchDone) {
        if (!filters.priceRange.length) {
          setFilters((prev) => ({
            ...prev,
            priceRange: [minPrice, maxPrice],
          }));
          setTempPriceRange([minPrice, maxPrice]);
        }
        if (!filters.sizeRange.length) {
          setFilters((prev) => ({
            ...prev,
            sizeRange: [minSize, maxSize],
          }));
          setTempSizeRange([minSize, maxSize]);
        }
        setInitialFetchDone(true);
      }

      if (filters.priceRange.length) {
        filteredItems = filteredItems.filter(
          (item) =>
            item.price >= filters.priceRange[0] &&
            item.price <= filters.priceRange[1]
        );
      }

      if (filters.sizeRange.length) {
        filteredItems = filteredItems.filter(
          (item) =>
            item.size >= filters.sizeRange[0] &&
            item.size <= filters.sizeRange[1]
        );
      }

      if (filters.furnished) {
        filteredItems = filteredItems.filter(
          (item) => item.furnished == parseInt(filters.furnished)
        );
      }

      if (filters.location) {
        filteredItems = filteredItems.filter(
          (item) => item[`location_${locale}`] === filters.location
        );
      }

      const uniqueLocations = [
        ...new Set(response.data.map((item) => item[`location_${locale}`])),
      ];
      setLocations(uniqueLocations);

      setAllItems(filteredItems);
      setPage(1);
    } catch (error) {
      console.error("Error fetching items:", error);
      setAllItems([]);
    }
    setLoading(false);
  }, [filters, locale, initialFetchDone]);

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  useEffect(() => {
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setItems(allItems.slice(startIndex, endIndex));
  }, [allItems, page]);

  const handleChange = (name, value) => {
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    const element = document.getElementById("Start");
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  const handleReset = () => {
    setFilters({
      unitType: "",
      bedroomsNumber: "",
      priceRange: minMaxPrice,
      sizeRange: minMaxSize,
      furnished: "",
      location: "",
    });
    setTempPriceRange(minMaxPrice);
    setTempSizeRange(minMaxSize);
    setPage(1);
  };

  const [open, setOpen] = useState(false);

  const content = (
    <>
      <div className="px-6 xl:px-0 filters">
        <button
          className="sm:hidden border-[1px] border-gold text-gold block w-full py-2 font-normal rounded-md mb-8"
          onClick={() => setOpen(!open)}
        >
          <FormattedMessage id="open_filters" />
        </button>
        <div
          className={
            open
              ? "h-96 transition-all duration-300 overflow-hidden sm:overflow-visible sm:h-auto"
              : "h-0 transition-all duration-300 overflow-hidden sm:overflow-visible sm:h-auto"
          }
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-x-8 gap-y-4 mt-1">
            <div className="">
              <Select
                label={intl.formatMessage({ id: "type" })}
                color="blue-gray"
                value={filters.unitType}
                onChange={(value) => handleChange("unitType", value)}
              >
                <Option value="">
                  <FormattedMessage id="type" />
                </Option>
                <Option value="apartment">
                  <FormattedMessage id="apartments" />
                </Option>
                <Option value="villa">
                  <FormattedMessage id="villas" />
                </Option>
                <Option value="townhouse">
                  <FormattedMessage id="townhouses" />
                </Option>
                <Option value="duplex">
                  <FormattedMessage id="duplex" />
                </Option>
                <Option value="penthouses">
                  <FormattedMessage id="penthouses" />
                </Option>
                <Option value="bulk_unit_rent">
                  <FormattedMessage id="bulk_unit_rent" />
                </Option>
                <Option value="compound">
                  <FormattedMessage id="compound" />
                </Option>
              </Select>
            </div>
            <div className="">
              <Select
                label={intl.formatMessage({ id: "bed_num" })}
                color="blue-gray"
                value={filters.bedroomsNumber}
                onChange={(value) => handleChange("bedroomsNumber", value)}
              >
                <Option value="">
                  <FormattedMessage id="bed_num" />
                </Option>
                <Option value="1">1</Option>
                <Option value="2">2</Option>
                <Option value="3">3</Option>
                <Option value="4">4</Option>
                <Option value="5">5</Option>
                <Option value="5+">5+</Option>
              </Select>
            </div>

            <div className="">
              <Select
                label={intl.formatMessage({ id: "furnished" })}
                color="blue-gray"
                value={filters.furnished}
                onChange={(value) => handleChange("furnished", value)}
              >
                <Option value="">
                  <FormattedMessage id="furnished" />
                </Option>
                <Option value="0">
                  <FormattedMessage id="yes" />
                </Option>
                <Option value="1">
                  <FormattedMessage id="no" />
                </Option>
              </Select>
            </div>

            <div className="">
              <Select
                label={intl.formatMessage({ id: "location" })}
                color="blue-gray"
                value={filters.location}
                onChange={(value) => handleChange("location", value)}
              >
                <Option value="">
                  <FormattedMessage id="location" />
                </Option>
                {locations.map((location, index) => (
                  <Option value={location} key={index}>
                    {location}
                  </Option>
                ))}
              </Select>
            </div>
          </div>

          <div className="grid px-2 sm:px-0 grid-cols-1 sm:grid-cols-3 gap-4 sm:gap-8 mt-2 sm:mt-4  sm:my-12">
            <div className="flex flex-col justify-between text-sm text-white items-center">
              <div className="flex justify-between mb-1 sm:mb-0 w-full">
                <span>${filters.priceRange[0]}</span>
                <span>${filters.priceRange[1]}</span>
              </div>
              <Range
                values={tempPriceRange}
                min={minMaxPrice[0]}
                max={minMaxPrice[1]}
                onChange={(values) => {
                  setTempPriceRange(values);
                }}
                onFinalChange={(values) => {
                  handleChange("priceRange", values);
                }}
                renderTrack={({ props, children }) => (
                  <div
                    {...props}
                    style={{
                      ...props.style,
                      height: "6px",
                      width: "100%",
                      background: getTrackBackground({
                        values: filters.priceRange,
                        colors: ["#ffffff", "#d3a188", "#ffffff", "#d3a188"],
                        min: minMaxPrice[0],
                        max: minMaxPrice[1],
                      }),
                    }}
                  >
                    {children}
                  </div>
                )}
                renderThumb={({ props }) => (
                  <div
                    {...props}
                    style={{
                      ...props.style,
                      height: "16px",
                      width: "16px",
                      borderRadius: "8px",
                      backgroundColor: "#D3A188",
                    }}
                  />
                )}
              />
            </div>

            <div className="flex flex-col justify-between text-sm text-white items-center">
              <div className="flex mb-1 sm:mb-0 justify-between w-full">
                <span>
                  {filters.sizeRange[0]} <FormattedMessage id="sqft" />
                </span>
                <span>
                  {filters.sizeRange[1]} <FormattedMessage id="sqft" />
                </span>
              </div>
              <Range
                values={tempSizeRange}
                min={minMaxSize[0]}
                max={minMaxSize[1]}
                onChange={(values) => {
                  setTempSizeRange(values);
                }}
                onFinalChange={(values) => {
                  handleChange("sizeRange", values);
                }}
                renderTrack={({ props, children }) => (
                  <div
                    {...props}
                    style={{
                      ...props.style,
                      height: "6px",
                      width: "100%",
                      background: getTrackBackground({
                        values: filters.sizeRange,
                        colors: ["#ffffff", "#d3a188", "#ffffff", "#d3a188"],
                        min: minMaxSize[0],
                        max: minMaxSize[1],
                      }),
                    }}
                  >
                    {children}
                  </div>
                )}
                renderThumb={({ props }) => (
                  <div
                    {...props}
                    style={{
                      ...props.style,
                      height: "16px",
                      width: "16px",
                      borderRadius: "8px",
                      backgroundColor: "#D3A188",
                    }}
                  />
                )}
              />
            </div>

            <button
              className="border-[1px] capitalize border-gold text-gold px-6 py-1 sm:py-2 font-normal rounded-md"
              onClick={handleReset}
            >
              <FormattedMessage id="reset" />
            </button>
          </div>
        </div>
        {loading ? (
          <LoadingIndicator message={<FormattedMessage id="search_text" />} />
        ) : (
          <div className="" id="Start">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 my-12">
              {items &&
                items.map((item, index) => {
                  return (
                    <CardItem
                      data={item}
                      key={index}
                      path="rents"
                      pagePath="rent"
                    />
                  );
                })}
            </div>

            <div className="my-12 flex justify-end gap-4">
              {Array.from(
                { length: Math.ceil(allItems.length / itemsPerPage) },
                (_, index) => (
                  <button
                    key={index}
                    onClick={() => handlePageChange(index + 1)}
                    className={
                      page === index + 1
                        ? "active w-6 h-8 sm:w-8 sm:h-8 text-gold border-2 border-gold rounded-sm scale-125"
                        : "sm:w-8 w-6 h-8 sm:h-8 text-white border-2 border-white rounded-sm"
                    }
                  >
                    {index + 1}
                  </button>
                )
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );

  return (
    <>
      <ReactLenis root options={{ duration: 4 }}>
        <HomeBanner path="rent" />
        <StandardContainer content={content} bgColor="py-12 sm:py-24" />;
      </ReactLenis>
    </>
  );
}
